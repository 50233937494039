<template>
	<edit-template>

    <div class="form-body" style="padding-top: 60rem; padding-left: 40rem">
      <el-form ref="form" :model="formData" :rules="rules" label-width="140rem">
        <el-form-item label="报名学校">
          {{ formData.school_name }}
        </el-form-item>
        <el-form-item label="入学年级">
          {{ formData.grade_name }}
        </el-form-item>
        <el-form-item label="入学年份">
          {{ formData.grade_year }}
        </el-form-item>
        <el-form-item label="缴费时间">
          {{ formData.pay_time }}
        </el-form-item>
        <el-form-item label="学生类型">
          {{ formData.student_type }}
        </el-form-item>
        <el-form-item label="学生姓名">
          {{ formData.student_name }}
        </el-form-item>
        <el-form-item label="学生身份证号">
          {{ formData.student_idcard }}
        </el-form-item>
        <el-form-item label="住宿寝室">
          {{ formData.standard_name }}
        </el-form-item>
        <el-form-item v-if="formData.study_type" label="学习方向">
          {{ formData.study_type }}
        </el-form-item>
        <el-form-item v-if="formData.major" label="专业方向">
          {{ formData.major }}
        </el-form-item>
        <el-form-item label="实缴金额" prop="payment_amount">
          <el-input v-if="formData.account_id+'' === '0'" v-model="formData.payment_amount" maxlength="10" placeholder="请输入实缴金额"></el-input>
          <div v-else="">{{ formData.payment_amount }}</div>
        </el-form-item>
        <el-form-item label="是否为培优生" prop="is_training" required="">
          <el-select v-model="formData.is_training" :popper-append-to-body="false" class="select" placeholder="请选择">
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="家长姓名">
          <el-input v-model="formData.consignee" maxlength="14" placeholder="请输入家长姓名"></el-input>
        </el-form-item>
        <el-form-item label="家长手机号">
          <el-input v-model="formData.consignee_mobile" maxlength="11" placeholder="请输入家长手机号"></el-input>
        </el-form-item>
        <el-form-item :inline="true" label="家庭住址">
          <div style="display: flex">
            <el-form-item prop="province">
              <el-select v-model="formData.province" :popper-append-to-body="false" class="select" placeholder="请选择省" @change="changeProvince">
                <el-option v-for="(item, index) in province" :key="index" ref="prove" :label="item.address_name" :value="item.address_code"></el-option>
              </el-select>
            </el-form-item>
            -
            <el-form-item prop="city">
              <el-select v-model="formData.city" :popper-append-to-body="false" class="select" placeholder="请选择市" @change="changeCity">
                <el-option v-for="(item, index) in city" :key="index" ref="prove" :label="item.address_name" :value="item.address_code"></el-option>
              </el-select>
            </el-form-item>
            -
            <el-form-item prop="area">
              <el-select v-model="formData.area" :popper-append-to-body="false" class="select" placeholder="请选择区" @change="changeArea">
                <el-option v-for="(item, index) in area" :key="index" ref="prove" :label="item.address_name" :value="item.address_code"></el-option>
              </el-select>
            </el-form-item>
            -
            <el-form-item prop="address">
              <el-input v-model="formData.address" maxlength="30" placeholder="请输入详细地址"></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item ref="img-box" label="缴费凭证">
          <input v-show="false" ref="imgInput" accept="image/png, image/jpeg, image/jpg" multiple="" type="file" @change="addImg">
          <el-button class="replace-img" style="margin-bottom: 12rem;display: flex;align-items: center" @click="btnClick">点击上传</el-button>
          <el-upload :before-upload="imgUploadBefore" :file-list="formData.payment_voucher" :headers="headers" :http-request="uploadImg" :limit="5" :on-preview="imgPreview" :on-remove="imgUploadRemove" accept="image/png, image/jpeg, image/jpg" action="http://www.baidu.com" list-type="picture-card" multiple="" name="image">
            <i class="el-icon-plus"></i>
          </el-upload>
          <p style="color: #999">（请上传5张及以内小于20M的png/jpg格式的图片）</p>
          <el-dialog :visible.sync="dialogVisible">
            <img :src="dialogVisibleUrl" alt="" width="100%">
          </el-dialog>
        </el-form-item>
        <el-form-item label="线下补录时间: ">{{ formData.created_at }}</el-form-item>
<!--        <el-form-item label="家长填写信息时间: ">2023-08-01 15:54:34</el-form-item>-->
      </el-form>
    </div>
    <template slot="footer">
      <el-button :loading="loading" type="primary" @click="submit">提交</el-button>
      <el-button @click="$confirm('取消编辑?', '提示', { type: 'warning' }).then(res => $router.back())">取消</el-button>
      <el-button @click="viewChangeLog">变更记录</el-button>
      <el-button @click="onRefund">{{ isRefund ? "查看退费详情" : "发起退费" }}</el-button>
    </template>

    <el-dialog :before-close="()=>changeDialog = false" :visible.sync="changeDialog" width="30%">
      <span>
      <div v-for="item in logList" v-if="logList.length>0" class="history-box flex flex-between">
        <div class="flex flex-between" style="width: 10rem">
          <div class="step-box" style="padding-top: 16rem">
            <div class="cir"></div>
            <span class="cir-line"></span>
          </div>
        </div>
        <div style="width: calc(100% - 50rem);padding: 0 0 30rem">
          <p>{{ item.change_detail }}</p>
        </div>
      </div>
      <t-result v-if="!logList.length" :type="'empty'"></t-result>
      </span>
    </el-dialog>

</edit-template>
</template>
<script>
import uploadImg from '@/components/common/upload.vue'
import TResult from "@/components/Result.vue";
import axios from "../../../../utils/axios.register";
export function getDetails(id){
  return axios.get('api/recruit-v2/offline/detail?formal_information_id=' + id)
}
export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  components: {TResult, uploadImg},
  data() {
    return {
      loading: false,
      // 表单数据
      formData: {
        school_id: '',
        parent_name: '',
        student_type: '',
        grade_name: '',
        grade_year: '',
        student_name: '',
        student_idcard: '',
        payment_amount: '',
        account_id: 1,
        payment_voucher: []
      },
      // 表单验证规则
      rules: {
        consignee: [
          {required: true, message: '请输入家长姓名', trigger: 'change'},
          {min: 1, max: 14, message: '姓名需在14个字符内', trigger: 'change'}
        ],
        payment_amount: [
          {required: true, message: '请输入实缴金额', trigger: 'change'},
          {pattern: /^[0-9]+(.[0-9]{1,2})?$/, message: '请输入正确的金额', trigger: 'change'}
        ],
        consignee_mobile: [
          {required: true, message: '请输入家长手机号', trigger: 'change'},
          {pattern: /^1[23456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'change'},
        ],
        is_training:{required:true,message:"请选择是过是否为培优生"}
        // address: [
        //   {required: true, message: '请输入详细地址', trigger: 'change'},
        // ],
        // province: [
        //   {required: true, message: '请选择省份', trigger: 'change'}
        // ],
        // city: [
        //   {required: true, message: '请选择城市', trigger: 'change'}
        // ],
        // area: [
        //   {required: true, message: '请选择区域', trigger: 'change'}
        // ],
      },
      dialogImageUrl: '',
      dialogVisible: false,
      dialogVisibleUrl: '',
      // 选择框配置
      selectConfig: {},
      headers: {
        authorization: 'Bearer ' + sessionStorage.getItem('TOKEN')
      },
      changeDialog: false,
      logList: [],
      province: [],
      city: [],
      area: [],
      // 退费相关
      isRefund:false
    }
  },
  methods: {
    onRefund(){
      if(this.isRefund){
        this.$router.push('./refund-details?id='+this.$route.query.id)
      }else{
        if(this.formData.refund_error)return this.$message.warning(this.formData.refund_error)
        this.$router.push('./refund?id='+this.$route.query.id)
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //图片上传前的回调
    imgUploadBefore(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 20

      if (!isJPG) {
        this.$message({
          type: 'error',
          message: '上传图片只能是 jpg/png 格式!',
          duration: 2000
        })
      }
      if (!isLt2M) {
        this.$message({
          type: 'error',
          message: '上传图片大小不能超过 20MB!',
          duration: 2000
        })
      }
      return isJPG && isLt2M
    },

    //删除图片回调
    imgUploadRemove(file, fileList) {
      this.formData.payment_voucher = fileList
    },

    //点击图片时的回调
    imgPreview(file) {
      this.dialogVisible = true
      this.dialogVisibleUrl = file.url
    },

    uploadImg(file) {
      let boo = this.imgUploadBefore(file.file)
      if (boo) {
        const loading = this.$loading({
          lock: true,
          text: '上传中',
          customClass: 'img-upload-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$uploadToOSS_1(file.file, 3).then(res => {
          this.formData.payment_voucher.push({
            name: file.file.name,
            url: res
          })
        }).finally(() => {
          loading.close()
        })
      }
    },

    btnClick() {
      this.$refs.imgInput.value = ''
      this.$refs.imgInput.click()
    },

    addImg(e) {
      let list = e.target.files
      let count = 5 - this.formData.payment_voucher.length
      Object.keys(list).forEach(async (item, index) => {
        if (index < count) {
          this.uploadImg({file: list[item]})
        }
      })
    },

    // 查看变更记录
    viewChangeLog() {
      this.$_register.get('api/recruit/common/get-opt-history', {
        params: {
          tbl_name: 'formal_information_fresh', tbl_id: this.formData.formal_information_id
        }
      }).then(res => {
        this.changeDialog = true
        this.logList = res.data.data.list
      })
    },

    changeProvince(val) {
      this.city = []
      this.area = []
      this.$_register.get('/api/recruit/common/area', {params: {address_code: val}}).then(res => {
        this.city = res.data.data
        this.formData.city = ''
        this.formData.area = ''
        this.formData.address = ''
      })
    },

    changeCity(val) {
      this.area = []
      this.$_register.get('/api/recruit/common/area', {params: {address_code: val}}).then(res => {
        this.area = res.data.data
        this.formData.area = ''
        this.formData.address = ''
      })
    },

    changeArea(val) {
      this.formData.address = ''
    },

    // 表单提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.formData.detail_address = ''
          if (this.province.find(item => item.address_code === this.formData.province)) {
            this.formData.detail_address += this.province.find(item => item.address_code === this.formData.province).address_name
          }
          if (this.city.find(item => item.address_code === this.formData.city)) {
            this.formData.detail_address += this.city.find(item => item.address_code === this.formData.city).address_name
          }
          if (this.area.find(item => item.address_code === this.formData.area)) {
            this.formData.detail_address += this.area.find(item => item.address_code === this.formData.area).address_name
          }
          this.formData.detail_address += this.formData.address
          this.$_register.post('api/recruit-v2/offline/edit', this.formData).then(res => {
            this.$message.success('编辑成功')
            this.$router.back()
          })
        }
      })
    }
  },

  created() {
    this.$_register.get('api/recruit-v2/offline/config').then(res => {
      this.selectConfig = res.data.data
    })
    getDetails(this.$route.query.id).then(res => {
      this.formData = res.data.data
      this.formData.formal_information_id = this.$route.query.id
      this.isRefund = res.data.data.refunded;
      this.$_register('/api/recruit/common/area', {params: {address_code: '000000'}}).then(res => {
        this.province = res.data.data
      })
      if (res.data.data.province) {
        this.$_register('/api/recruit/common/area', {params: {address_code: res.data.data.province}}).then(res => {
          this.city = res.data.data
        })
      }
      if (res.data.data.city) {
        this.$_register('/api/recruit/common/area', {params: {address_code: res.data.data.city}}).then(res => {
          this.area = res.data.data
        })
      }
    })
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-form .el-input {
  width: 250rem !important;
}

.zhu-row-line {
  font-size: 14rem;
  line-height: 40rem;
  margin-bottom: 22rem;

  .label {
    color: #666;
    float: left;
    padding-right: 12rem;
    text-align: right;
    vertical-align: middle;
    width: 140rem;
  }
}

::v-deep .el-upload--picture-card {
  display: none;
}

.history-box {
  .step-box {
    position: relative;
  }

  .cir {
    background-color: #1C4EFD;
    border-radius: 50%;
    height: 15rem;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 15rem;
    z-index: 1;
  }

  .cir-line {
    background-color: #ccc;
    height: 100%;
    left: 50%;
    position: absolute;
    top: 15rem;
    transform: translateX(-50%);
    width: 1rem;
  }

  .div1 > div {
    color: #333;
    height: 66rem;
    line-height: 66rem;
    padding-left: 20rem;
    padding-right: 40rem;
    white-space: nowrap;
    word-break: keep-all;
  }

  &:last-child {
    .cir-line {
      display: none;
    }
  }
}
</style>
